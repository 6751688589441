.swiper-button-next {

  position: absolute;
  color: white !important;

}

.swiper-button-prev {

  position: absolute;
  color: white !important;

}