/* Active navlink class before navbar toggle */
.navbar-active {
	color: #004b95;
	position: relative;
     transition: all 0.3s ease-in;
	 font-weight: 500;
}

.navbar-active::after {
	content: "";
	width: 100%;
	height: 2px;
	position: absolute;
	bottom: -5px;
	left: 0;
	background-color: #004b95;
     transition: all 0.3s ease-in;
}

/* Inactive navlink class before navbar toggle */
.navbar-inactive {
     color: #000000;
     transition: all 0.3s ease-in;
}

/* Active navlink class after navbar toggle */
.navbar-active-white {
	color: #ffffff;
	font-weight: bold;
	position: relative;
     transition: all 0.3s ease-in;
}

.navbar-active-white::after {
	content: "";
	width: 100%;
	height: 2px;
	position: absolute;
	bottom: -5px;
	left: 0;
	background-color: #ffffff;
     transition: all 0.3s ease-in;
}

/* Inactive navlink class after navbar toggle */
.navbar-inactive-white{
     color: #ffffff;
     transition: all 0.3s ease-in;
}