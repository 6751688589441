/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.embed-responsive.embed-responsive-16by9 iframe {
  clip-path: inset(1px 1px);
}
.discriptionTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}

.discriptionTruncate-15 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}

.clip-path-container-left {
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  overflow: visible;
}
.clip-path-container-right {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.sliderContainer img {
  animation: fadeImg 1s ease-out;
}

@keyframes fadeImg {
  from {
    opacity: 09;
  }

  to {
    opacity: 1;
  }
}

.quotesSlider .quoteBGSlide {
  animation: fadeQuotes 3s ease-out;
}

@keyframes fadeQuotes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}

@keyframes jumpInfinite {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.right-section {
  position: absolute;
  top: 100px;
  scale: 0;
  transition-duration: 600ms;
}

/* .active {
	scale: 1;
	transform: translate(600px, 0px);
	transition-duration: 700ms;
}

@media screen and (max-width: 380px) {
	.active {
		transform: translate(10px, 10px);
		top: 450px;
		left: -10px;
	}
}

.mob {
	scale: 0;
}

.mobactive {
	scale: 1;
	transition-duration: 700ms;
} */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  opacity: 0.5;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 185, 185);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9ca3af;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #004b95;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
